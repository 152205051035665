<template>
<div class="container is-fluid">
  <h3 class="title is-3">🥥</h3>
  <commit></commit>
</div>
</template>

<script>
import commit from '@/components/dev/commit'

export default {
  components: { commit },
}
</script>
